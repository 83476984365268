var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import store, { loadConciergeServices, setMetaInfo } from '@/store';
import * as types from '@/types';
import api, { getPageImageURL, getSpecificPageImageURL } from '@/api';
import PageComponent from '@/lib/page-component';
import * as seo from '@/router/seo';
function routeUpdate(to, from, next) {
    return __awaiter(this, void 0, Promise, function () {
        var slug1, slug2, conciergeServices, conciergeServicesDetails, _i, _a, serviceType, _b, _c, serviceDetails;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    slug1 = to.params.slug1;
                    slug2 = to.params.slug2;
                    return [4 /*yield*/, loadConciergeServices(store)];
                case 1:
                    conciergeServices = _d.sent();
                    conciergeServicesDetails = null;
                    for (_i = 0, _a = conciergeServices.service_types; _i < _a.length; _i++) {
                        serviceType = _a[_i];
                        // if (serviceType.slug !== slug1) {
                        //     continue;
                        // }
                        for (_b = 0, _c = serviceType.subPages; _b < _c.length; _b++) {
                            serviceDetails = _c[_b];
                            if (serviceDetails.slug === slug2) {
                                conciergeServicesDetails = serviceDetails;
                                break;
                            }
                        }
                    }
                    next(function (vm) {
                        vm.$data.conciergeServicesDetails = conciergeServicesDetails;
                    });
                    return [2 /*return*/];
            }
        });
    });
}
export default PageComponent.extend({
    data: function () {
        var options = [];
        var periods = ['AM', 'PM'];
        for (var h = 0; h < 24; h++) {
            for (var m = 0; m < 60; m += 15) {
                var period = periods[h < 12 ? 0 : 1];
                var hour = h === 0 ? 12 : h > 12 ? h - 12 : h;
                var minute = m === 0 ? '00' : m;
                // @ts-ignore
                var timeValue = "".concat(h.toString().padStart(2, '0'), ":").concat(minute);
                // @ts-ignore
                var text = "".concat(hour.toString().padStart(2, '0'), ":").concat(minute, " ").concat(period);
                options.push({
                    value: timeValue,
                    text: text,
                });
            }
        }
        return {
            conciergeServicesDetails: null,
            times: options,
            bookExperience: {
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                additionalInformation: '',
                destination: '',
                lodgingBookingStatus: '',
                interest: '',
                preferedDate: '',
                preferedTime: '',
                validationErrors: {},
            },
            SubmitBtnText: "Submit",
            CanSubmit: true
        };
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                setTimeout(function () {
                    _this.updateHeader();
                }, 100);
                return [2 /*return*/];
            });
        });
    },
    methods: {
        getTinyImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.tiny);
        },
        getSmallImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.small);
        },
        getMediumImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.medium);
        },
        getLargeImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.large);
        },
        getExtraLargeImage: function (pageId) {
            return getPageImageURL(pageId, types.ImageSize.extra_large);
        },
        setImage: function (pageId) {
            return "background-image: url('".concat(getSpecificPageImageURL(pageId, types.ImageSize.extra_large), "')");
        },
        goToForm: function () {
            var element = document.getElementById('book-experience');
            var headerOffset = 50;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.scrollY - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        },
        validate: function () {
            var _this = this;
            var errors = {};
            errors.firstName = this.$data.bookExperience.firstName.trim() == "";
            errors.lastName = this.$data.bookExperience.lastName.trim() == "";
            errors.phone = this.$data.bookExperience.phone.trim() == "";
            errors.email = this.$data.bookExperience.email.trim() == "";
            errors.destination = this.$data.bookExperience.destination.trim() == "";
            errors.lodgingBookingStatus = this.$data.bookExperience.lodgingBookingStatus.trim() == "";
            errors.interest = this.$data.bookExperience.interest.trim() == "";
            this.$data.bookExperience.validationErrors = errors;
            if (errors.firstName || errors.lastName || errors.phone || errors.email || errors.destination || errors.lodgingBookingStatus || errors.interest)
                return false;
            this.$data.SubmitBtnText = "Please wait...";
            this.$data.CanSubmit = false;
            setTimeout(function () {
                _this.$data.CanSubmit = true;
            }, 5000);
            return true;
        },
        recaptchaCallback: function (recaptchaToken) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.$data.bookExperience.recaptcha = recaptchaToken;
                            this.$data.SubmitBtnText = "Send";
                            this.$data.CanSubmit = true;
                            return [4 /*yield*/, api.bookExperience(this.$data.bookExperience)];
                        case 1:
                            _a.sent();
                            this.$data.bookExperience.firstName = '';
                            this.$data.bookExperience.lastName = '';
                            this.$data.bookExperience.phone = '';
                            this.$data.bookExperience.email = '';
                            this.$data.bookExperience.additionalInformation = '';
                            this.$data.bookExperience.destination = '';
                            this.$data.bookExperience.lodgingBookingStatus = '';
                            this.$data.bookExperience.interest = '';
                            this.$data.bookExperience.preferedDate = '';
                            this.$data.bookExperience.preferedTime = '';
                            alert("Thank you for your submission. We will be in touch shortly.");
                            return [2 /*return*/];
                    }
                });
            });
        },
        OnSubmit: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.validate()) {
                        // @ts-ignore
                        this.$refs.invisibleRecaptchaContact.execute();
                    }
                    return [2 /*return*/];
                });
            });
        },
        updateHeader: function () {
            return __awaiter(this, void 0, void 0, function () {
                var width, metaInfoData;
                return __generator(this, function (_a) {
                    this.setTitle('');
                    width = window.innerWidth;
                    // if ( width > 991 && this.conciergeServicesDetails.video ) {
                    //     //this.setBackgroundLink(`https://www.youtube.com/embed/${this.conciergeServicesDetails.video}?autoplay=1&mute=1&controls=0&rel=0&loop=1&disablekb=1&playlist=${this.conciergeServicesDetails.video}`)
                    //     this.setBackgroundLink(`https://www.youtube.com/embed/${this.conciergeServicesDetails.video}?autoplay=1&mute=1&controls=0&rel=0&loop=1&disablekb=1&showinfo=0&color=black&modestbranding=1&playsinline=1&enablejsapi=1&playlist=${this.conciergeServicesDetails.video}`)
                    // }else{
                    //     this.setBackgroundLink( this.conciergeServicesDetails.video ? getSpecificPageImageURL(this.conciergeServicesDetails.id, types.ImageSize.extra_large, 3) : "" )
                    // }
                    this.setBreadcrumbs([]);
                    metaInfoData = {
                        title: this.conciergeServicesDetails.meta_title,
                        meta: [{
                                vmid: 'description',
                                name: 'description',
                                content: this.conciergeServicesDetails.meta_description
                            }, {
                                vmid: 'keywords',
                                name: 'keywords',
                                content: this.conciergeServicesDetails.meta_keywords
                            }],
                        script: seo.getLdJson(seo.getDefaultPageLocation())
                    };
                    setMetaInfo(store, metaInfoData);
                    return [2 /*return*/];
                });
            });
        },
    },
    computed: {},
    beforeRouteEnter: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, routeUpdate(to, from, next)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
});
