import { render, staticRenderFns } from "./type.vue?vue&type=template&id=700f68a7&scoped=true&lang=pug"
import script from "./type.vue?vue&type=script&lang=ts"
export * from "./type.vue?vue&type=script&lang=ts"
import style0 from "./type.vue?vue&type=style&index=0&id=700f68a7&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700f68a7",
  null
  
)

export default component.exports